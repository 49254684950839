export const LISTITEMS: any[] = [
    {
        url: 'admin/service-repair/list',
        name: 'Servis Listesi',
        icon: 'ft-settings'
    },
    {
        url: 'admin/service-repair/add',
        name: 'Servis Ekle',
        icon: 'ml-2'
    },
    {
        url: 'admin/service-dealer/list',
        name: 'Bayi Listesi',
        icon: 'ft-users'
    },
    {
        url: 'admin/service-dealer/add',
        name: 'Bayi Ekle',
        icon: 'ml-2'
    },
    {
        url: 'admin/service-stock/list',
        name: 'Yedek Parça Listesi',
        icon: 'ft-box'
    },
    {
        url: 'admin/service-stock/add',
        name: 'Yedek Parça Ekle',
        icon: 'ml-2'
    },
    { url: 'admin/service-car/list', name: 'Araç Listesi', icon: 'ft-truck' },
    { url: 'admin/service-car/add', name: 'Araç Ekle', icon: 'ml-2' },
    {
        url: 'admin/service-customer/list',
        name: 'Müşteri Listesi',
        icon: 'ft-user-check'
    },
    {
        url: 'admin/service-customer/add',
        name: 'Müşteri Ekle',
        icon: 'ml-2'
    },
    {
        url: 'admin/income-expense/list',
        name: 'Gelir Gider',
        icon: 'ft-trending-up'
    },
    { url: 'admin/cashbox', name: 'Kasa Raporu', icon: 'ft-pocket' }
];
