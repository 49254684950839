import { Component, ViewContainerRef, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { Router, NavigationEnd } from '@angular/router';
import { filter } from 'rxjs/operators';
import { SpinnerService } from '@shared/services/spinner.service';
import { LocaleService } from './locale.service';
import { TranslateService } from '@ngx-translate/core';
import { SessionStorageService } from 'ngx-webstorage';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html'
})
export class AppComponent implements OnInit, OnDestroy {
    subscription: Subscription;

    constructor(
        private router: Router,
        private spinnerService: SpinnerService,
        private localeService: LocaleService,
        private sessionStroge: SessionStorageService
    ) {
        const langCode = sessionStroge.retrieve('langCode');
        // this.localeService.initLocale(langCode ? langCode : 'tr'); BUGFİX ?
        this.localeService.setLocale(langCode ? langCode : 'tr');
    }

    ngOnInit() {
        this.subscription = this.router.events
            .pipe(filter(event => event instanceof NavigationEnd))
            .subscribe(() => window.scrollTo(0, 0));
    }

    ngOnDestroy() {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
    }

    get showLoading() {
        return this.spinnerService.getShowLoading;
    }
}
