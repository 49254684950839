import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {HttpClient, HttpResponse} from '@angular/common/http';
import {LocalStorageService, SessionStorageService} from 'ngx-webstorage';
import {environment} from '@environments/environment';
import {map} from 'rxjs/operators';
import {Router} from '@angular/router';
import {IUser} from '../model/user.model';
import { TokenService } from './token.service';
import {JwtHelperService} from '@auth0/angular-jwt';

type EntityResponseType = HttpResponse<IUser>;

@Injectable({
    providedIn: 'root'
})
export class AuthService {
    private readonly resourceUrl: string = '/users';

    constructor(private router: Router,
                private http: HttpClient,
                private $localStorage: LocalStorageService,
                private $sessionStorage: SessionStorageService,
                private tokenService:TokenService,
                private jwtHelper: JwtHelperService) {
        this.resourceUrl = environment.url + this.resourceUrl;
    }

    login(user: IUser): Observable<EntityResponseType> {
        return this.http
            .post<IUser>(`${this.resourceUrl}/login`, user, {observe: 'response'})
            .pipe(map(authenticateSuccess.bind(this)));

        function authenticateSuccess(resp) {
            const jwt = resp.body.token;
            if (jwt) {
                this.storeAuthenticationToken(jwt);
                return jwt;
            }
        }
    }

    storeAuthenticationToken(jwt) {
        this.$localStorage.store('authenticationToken', jwt);
    }

    signUp(user: IUser): Observable<EntityResponseType> {
        return this.http
            .post<IUser>(`${this.resourceUrl}/add-user`, user, {observe: 'response'})
            .pipe(map((res: EntityResponseType) => res));
    }

    getToken() {
        return (
            this.$localStorage.retrieve('authenticationToken') ||
            this.$sessionStorage.retrieve('authenticationToken')
        );
    }

    logout(): Observable<any> {
        return new Observable(observer => {
            if (this.$localStorage.retrieve('authenticationToken')) {
                this.$localStorage.clear('authenticationToken');
            }
            if (this.$sessionStorage.retrieve('authenticationToken')) {
                this.$sessionStorage.clear('authenticationToken');
            }
            this.router.navigate(['/pages/login']);
            observer.complete();
        });
    }

    isAuthenticated() {
       return !this.jwtHelper.isTokenExpired(this.tokenService.getToken());
      // return true;
    }

    hasAnyAuthority(authorities: string[]): boolean {
        const jwtData = this.getToken();
        if (!jwtData) {
            return false;
        }
        const decodedJwtJsonData = window.atob(jwtData);
        const decodedJwtData = JSON.parse(decodedJwtJsonData);
        const apexRoles = decodedJwtData.apexRole;

        for (let i = 0; i < authorities.length; i++) {
            if (apexRoles.includes(authorities[i])) {
                return true;
            }
        }

        return false;
    }

    activateAccount(activationKey: string) {
        return this.http
            .get<any>(`${this.resourceUrl}/account/activate/` + activationKey, {observe: 'response'}).pipe(
                map(value => value.body)
            );
    }
}
