<!--Footer Starts-->
<footer class="footer">
  <p class="copyright clearfix text-muted m-0">
    Copyright &copy; {{ currentDate | date: "yyyy" }}
    <a id="pixinventLink" href="https://www.alparslanotoservis.com">
      ALPARSLANOTOSERVİS
    </a>
    <span class="d-none d-sm-inline-block">
      ,Tüm Hakları Saklıdır. Designed by aliparlatti</span
    >
  </p>
</footer>
<!--Footer Ends-->
