import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';

import {FilterPipe} from './filter.pipe';
import {SearchPipe} from './search.pipe';
import {ShortNamePipe} from './short-name.pipe';
import {ProductTypeMapperPipe} from '@shared/pipes/product-type-mapper.pipe';
import {RoleCheckConvertName} from '@shared/pipes/role-check-convert-name.pipe';

@NgModule({
    declarations: [FilterPipe, SearchPipe, ShortNamePipe, ProductTypeMapperPipe, RoleCheckConvertName],
    imports: [CommonModule],
    exports: [FilterPipe, SearchPipe, ShortNamePipe, ProductTypeMapperPipe, RoleCheckConvertName]
})

export class PipeModule {
}
