import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AngularFireModule } from '@angular/fire';
import { AngularFireAuthModule } from '@angular/fire/auth';

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ToastrModule } from 'ngx-toastr';
import { AgmCoreModule } from '@agm/core';
import {
    HTTP_INTERCEPTORS,
    HttpClient,
    HttpClientModule
} from '@angular/common/http';
import {
    TranslateLoader,
    TranslateModule,
    TranslateService
} from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { StoreModule } from '@ngrx/store';
import { DragulaService } from 'ng2-dragula';

import {
    PERFECT_SCROLLBAR_CONFIG,
    PerfectScrollbarConfigInterface,
    PerfectScrollbarModule
} from 'ngx-perfect-scrollbar';

import { AppRoutingModule } from './app-routing.module';
import { SharedModule } from '@shared/shared.module';
import * as fromApp from './store/app.reducer';
import { AppComponent } from './app.component';
import { ContentLayoutComponent } from './layouts/content/content-layout.component';
import { FullLayoutComponent } from './layouts/full/full-layout.component';

import { AuthService } from '@shared/auth/auth.service';
import { AuthGuard } from '@shared/auth/auth-guard.service';
import { WINDOW_PROVIDERS } from '@shared/services/window.service';
import { NgxWebstorageModule } from 'ngx-webstorage';
import { AuthInterceptor } from '@shared/interceptors/auth.interceptor';
import { AuthExpiredInterceptor } from '@shared/interceptors/auth-expired-interceptor';
import { ErrorInterceptor } from '@shared/interceptors/error.interceptor';
import { NgxSpinnerModule } from 'ngx-spinner';
import { JwtHelperService, JwtModule } from '@auth0/angular-jwt';
import { registerLocaleData } from '@angular/common';
import localeTr from '@angular/common/locales/tr';
import localeTrExtra from '@angular/common/locales/extra/tr';
import { IncomeExpenseComponent } from './pages/admin/pages/income-expense/income-expense.component';
import { LocaleProvider } from './locale.provider';

registerLocaleData(localeTr, 'tr', localeTrExtra);

const firebaseConfig = {
    apiKey: 'YOUR_API_KEY', // YOUR_API_KEY
    authDomain: 'YOUR_AUTH_DOMAIN', // YOUR_AUTH_DOMAIN
    databaseURL: 'YOUR_DATABASE_URL', // YOUR_DATABASE_URL
    projectId: 'YOUR_PROJECT_ID', // YOUR_PROJECT_ID
    storageBucket: 'YOUR_STORAGE_BUCKET', // YOUR_STORAGE_BUCKET
    messagingSenderId: 'YOUR_MESSAGING_SENDER_ID', // YOUR_MESSAGING_SENDER_ID
    appId: 'YOUR_APP_ID', // YOUR_APP_ID
    measurementId: 'YOUR_MEASUREMENT_ID' // YOUR_MEASUREMENT_ID
};

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
    suppressScrollX: true,
    wheelPropagation: false
};

export function createTranslateLoader(http: HttpClient) {
    return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({
    declarations: [
        AppComponent,
        FullLayoutComponent,
        ContentLayoutComponent,
        IncomeExpenseComponent
    ],
    imports: [
        BrowserAnimationsModule,
        StoreModule.forRoot(fromApp.appReducer),
        AppRoutingModule,
        SharedModule,
        HttpClientModule,
        AngularFireModule.initializeApp(firebaseConfig),
        NgxWebstorageModule.forRoot({
            prefix: 'appointment-center',
            separator: '-'
        }),
        AngularFireAuthModule,
        ToastrModule.forRoot(),
        NgbModule,
        JwtModule.forRoot({
            config: {
                tokenGetter: () => {
                    return localStorage.getItem(
                        'appointment-center-authenticationtoken'
                    );
                },
                allowedDomains: [
                    'localhost:2000',
                    'localhost:4000',
                    'https://alparslanotoservis.com/'
                ]
            }
        }),
        NgxSpinnerModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: createTranslateLoader,
                deps: [HttpClient]
            }
        }),
        AgmCoreModule.forRoot({
            apiKey: 'YOUR_GOOGLE_MAP_API_KEY'
        }),
        PerfectScrollbarModule
    ],
    providers: [
        JwtHelperService,
        AuthService,
        AuthGuard,
        DragulaService,
        TranslateService,
        LocaleProvider,
        {
            provide: PERFECT_SCROLLBAR_CONFIG,
            useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG
        },
        {
            provide: PERFECT_SCROLLBAR_CONFIG,
            useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG
        },
        WINDOW_PROVIDERS,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AuthInterceptor,
            multi: true
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AuthExpiredInterceptor,
            multi: true
        },
        { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true }
    ],

    bootstrap: [AppComponent]
})
export class AppModule {}
