import { Pipe, PipeTransform } from '@angular/core';
import { RoleEnum } from '../model/enum/role.enum';

@Pipe({
    name: 'roleCheckConvertName'
})
export class RoleCheckConvertName implements PipeTransform {
    transform(roles: any[]): any {
        return roles.map(item => item.name === RoleEnum.ROLE_CUSTOMER ? 'Müşteri' : 'Yönetici')
    }
}
