import {Injectable} from '@angular/core';
import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable} from 'rxjs';
import {tap} from 'rxjs/operators';
import {AuthService} from '@shared/services/auth.service';
import {Router} from '@angular/router';
@Injectable({
  providedIn: 'root'
})
export class AuthExpiredInterceptor implements HttpInterceptor {
  constructor(private authService: AuthService, private router: Router) {
  }
  intercept(
      request: HttpRequest<any>,
      next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
        tap(
            (event: HttpEvent<any>) => {
            },
            (err: any) => {
              if (err instanceof HttpErrorResponse) {
                if (err.status === 401 && this.router.url.includes('/public-dashboard')) {
                  return next.handle(request);
                } else if (err.status === 401) {
                  this.authService.logout().subscribe();
                }
              }
            }
        )
    );
  }
}
