import { RouteInfo } from './vertical-menu.metadata';
import { RoleEnum } from '../model/enum/role.enum';

// Sidebar menu Routes and data
export const ROUTES: RouteInfo[] = [
    {
        path: 'dashboard',
        title: 'home',
        icon: 'ft-home',
        class: '',
        badge: '',
        badgeClass: 'badge badge-pill badge-danger float-right mr-1 mt-1',
        isExternalLink: false,
        submenu: [],
        roles: [RoleEnum.ROLE_ADMIN]
    },
    {
        path: 'admin/service-stock/list',
        title: 'spare_parts_management',
        icon: 'fa fa-cog',
        class: '',
        badge: '',
        badgeClass: 'badge badge-pill badge-danger float-right mr-1 mt-1',
        isExternalLink: false,
        submenu: [],
        roles: [RoleEnum.ROLE_ADMIN]
    },
    {
        path: 'admin/service-customer/list',
        title: 'customers',
        icon: 'fa fa-users',
        class: '',
        badge: '',
        badgeClass: 'badge badge-pill badge-danger float-right mr-1 mt-1',
        isExternalLink: false,
        submenu: [],
        roles: [RoleEnum.ROLE_ADMIN]
    },
    {
        path: 'admin/service-dealer/list',
        title: 'dealers',
        icon: 'fa fa-delicious',
        class: '',
        badge: '',
        badgeClass: 'badge badge-pill badge-danger float-right mr-1 mt-1',
        isExternalLink: false,
        submenu: [],
        roles: [RoleEnum.ROLE_ADMIN]
    },
    {
        path: 'admin/service-car/list',
        title: 'cars',
        icon: 'fa fa-car',
        class: '',
        badge: '',
        badgeClass: 'badge badge-pill badge-danger float-right mr-1 mt-1',
        isExternalLink: false,
        submenu: [],
        roles: [RoleEnum.ROLE_ADMIN]
    },
    {
        path: 'admin/service-repair/list',
        title: 'repairs',
        icon: 'fa fa-cogs',
        class: '',
        badge: '',
        badgeClass: 'badge badge-pill badge-danger float-right mr-1 mt-1',
        isExternalLink: false,
        submenu: [],
        roles: [RoleEnum.ROLE_ADMIN]
    },
    {
        path: 'admin/income-expense/list',
        title: 'income-expense',
        icon: 'fa fa-calculator',
        class: '',
        badge: '',
        badgeClass: 'badge badge-pill badge-danger float-right mr-1 mt-1',
        isExternalLink: false,
        submenu: [],
        roles: [RoleEnum.ROLE_ADMIN]
    },
    {
        path: 'admin/cashbox',
        title: 'cashbox',
        icon: 'fa fa-money',
        class: '',
        badge: '',
        badgeClass: 'badge badge-pill badge-danger float-right mr-1 mt-1',
        isExternalLink: false,
        submenu: [],
        roles: [RoleEnum.ROLE_ADMIN]
    },
    {
        path: 'customer/product/list',
        title: 'Ev Arsa Satın Al',
        icon: 'ft-home',
        class: '',
        badge: '',
        badgeClass: 'badge badge-pill badge-danger float-right mr-1 mt-1',
        isExternalLink: false,
        submenu: [],
        roles: [RoleEnum.ROLE_CUSTOMER]
    },
    {
        path: 'customer/product/purchased',
        title: 'Satın Alınanlar',
        icon: 'ft-home',
        class: '',
        badge: '',
        badgeClass: 'badge badge-pill badge-danger float-right mr-1 mt-1',
        isExternalLink: false,
        submenu: [],
        roles: [RoleEnum.ROLE_CUSTOMER]
    }
];
