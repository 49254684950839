import {
    Component,
    Output,
    EventEmitter,
    OnDestroy,
    OnInit,
    AfterViewInit,
    ChangeDetectorRef,
    Inject,
    Renderer2,
    ViewChild,
    ElementRef,
    ViewChildren,
    QueryList,
    HostListener,
    LOCALE_ID
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { LayoutService } from '../services/layout.service';
import { Subscription } from 'rxjs';
import { ConfigService } from '../services/config.service';
import { DOCUMENT } from '@angular/common';
import { CustomizerService } from '../services/customizer.service';
import { FormControl } from '@angular/forms';
import { LISTITEMS } from '../data/template-search';
import { Router } from '@angular/router';
import { TokenService } from '@shared/services/token.service';
import { LocalStorageService, SessionStorageService } from 'ngx-webstorage';
import { AuthService } from '@shared/services/auth.service';
import { LocaleService } from '../../locale.service';
import { LocaleId } from '../../locale.provider';

@Component({
    selector: 'app-navbar',
    templateUrl: './navbar.component.html',
    styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit, AfterViewInit, OnDestroy {
    currentLang = 'tr';
    selectedLanguageText = 'Türkçe';
    selectedLanguageFlag = './assets/img/flags/tr.png';
    toggleClass = 'ft-maximize';
    placement = 'bottom-right';
    logoUrl = 'assets/img/logo.png';
    menuPosition = 'Side';
    isSmallScreen = false;
    protected innerWidth: any;
    searchOpenClass = '';
    transparentBGClass = '';
    hideSidebar: boolean = true;
    public isCollapsed = true;
    layoutSub: Subscription;
    configSub: Subscription;
    now: Date = new Date();
    @ViewChild('search') searchElement: ElementRef;
    @ViewChildren('searchResults') searchResults: QueryList<any>;

    @Output()
    toggleHideSidebar = new EventEmitter<Object>();

    @Output()
    seachTextEmpty = new EventEmitter<boolean>();

    listItems = [];
    control = new FormControl();

    public config: any = {};

    public user = null;

    constructor(
        public translate: TranslateService,
        private layoutService: LayoutService,
        private router: Router,
        private configService: ConfigService,
        private cdr: ChangeDetectorRef,
        private tokenService: TokenService,
        private authService: AuthService,
        private localeService: LocaleService,
        @Inject(LOCALE_ID) public localeId: LocaleId
    ) {
        // const browserLang: string = translate.getBrowserLang();
        // translate.use(browserLang.match(/en/) ? browserLang : 'tr');
        // console.log(browserLang);
        this.currentLang = this.localeService.currentLocale;
        this.selectedLanguageText =
            this.localeService.currentLocale === 'tr' ? 'Türkçe' : 'English';
        this.selectedLanguageFlag =
            this.localeService.currentLocale === 'tr'
                ? './assets/img/flags/tr.png'
                : './assets/img/flags/us.png';
        this.config = this.configService.templateConf;
        this.innerWidth = window.innerWidth;

        this.layoutSub = layoutService.toggleSidebar$.subscribe(isShow => {
            this.hideSidebar = !isShow;
        });
    }

    ngOnInit() {
        this.listItems = LISTITEMS;

        if (this.innerWidth < 1200) {
            this.isSmallScreen = true;
        } else {
            this.isSmallScreen = false;
        }

        this.user = this.tokenService.getPayload();
    }

    ngAfterViewInit() {
        this.configSub = this.configService.templateConf$.subscribe(
            templateConf => {
                if (templateConf) {
                    this.config = templateConf;
                }
                this.loadLayout();
                this.cdr.markForCheck();
            }
        );
    }

    ngOnDestroy() {
        if (this.layoutSub) {
            this.layoutSub.unsubscribe();
        }
        if (this.configSub) {
            this.configSub.unsubscribe();
        }
    }

    @HostListener('window:resize', ['$event'])
    onResize(event) {
        this.innerWidth = event.target.innerWidth;
        if (this.innerWidth < 1200) {
            this.isSmallScreen = true;
        } else {
            this.isSmallScreen = false;
        }
    }

    loadLayout() {
        if (
            this.config.layout.menuPosition &&
            this.config.layout.menuPosition.toString().trim() != ''
        ) {
            this.menuPosition = this.config.layout.menuPosition;
        }

        if (this.config.layout.variant === 'Light') {
            this.logoUrl = 'assets/img/logo-dark.png';
        } else {
            this.logoUrl = 'assets/img/logo.png';
        }

        if (this.config.layout.variant === 'Transparent') {
            this.transparentBGClass =
                this.config.layout.sidebar.backgroundColor;
        } else {
            this.transparentBGClass = '';
        }
    }

    onSearchKey(event: any) {
        if (this.searchResults && this.searchResults.length > 0) {
            this.searchResults.first.host.nativeElement.classList.add(
                'first-active-item'
            );
        }

        if (event.target.value === '') {
            this.seachTextEmpty.emit(true);
        } else {
            this.seachTextEmpty.emit(false);
        }
    }

    removeActiveClass() {
        if (this.searchResults && this.searchResults.length > 0) {
            this.searchResults.first.host.nativeElement.classList.remove(
                'first-active-item'
            );
        }
    }

    onEscEvent() {
        this.control.setValue('');
        this.searchOpenClass = '';
        this.seachTextEmpty.emit(true);
    }

    onEnter() {
        if (this.searchResults && this.searchResults.length > 0) {
            let url = this.searchResults.first.url;
            if (url && url != '') {
                this.control.setValue('');
                this.searchOpenClass = '';
                this.router.navigate([url]);
                this.seachTextEmpty.emit(true);
            }
        }
    }

    redirectTo(value) {
        this.router.navigate([value]);
        this.seachTextEmpty.emit(true);
    }

    ChangeLanguage(language: string) {
        const lang = this.localeService.currentLocale.startsWith('en')
            ? 'tr'
            : 'en';
        this.localeService.setLocale(lang);
        if (lang === 'tr') {
            this.selectedLanguageText = 'Türkçe';
            this.selectedLanguageFlag = './assets/img/flags/tr.png';
        } else if (lang === 'en') {
            this.selectedLanguageText = 'English';
            this.selectedLanguageFlag = './assets/img/flags/us.png';
        }
        window.location.reload();

        // else if (language === 'es') {
        //     this.selectedLanguageText = 'Spanish';
        //     this.selectedLanguageFlag = './assets/img/flags/es.png';
        // } else if (language === 'pt') {
        //     this.selectedLanguageText = 'Portuguese';
        //     this.selectedLanguageFlag = './assets/img/flags/pt.png';
        // } else if (language === 'de') {
        //     this.selectedLanguageText = 'German';
        //     this.selectedLanguageFlag = './assets/img/flags/de.png';
        // }
    }

    ToggleClass() {
        if (this.toggleClass === 'ft-maximize') {
            this.toggleClass = 'ft-minimize';
        } else {
            this.toggleClass = 'ft-maximize';
        }
    }

    toggleSearchOpenClass(display) {
        this.control.setValue('');
        if (display) {
            this.searchOpenClass = 'open';
            setTimeout(() => {
                this.searchElement.nativeElement.focus();
            }, 0);
        } else {
            this.searchOpenClass = '';
        }
        this.seachTextEmpty.emit(true);
    }
    logout() {
        {
            this.authService.logout().subscribe();
        }
    }

    toggleNotificationSidebar() {
        this.layoutService.toggleNotificationSidebar(true);
    }

    toggleSidebar() {
        this.layoutService.toggleSidebarSmallScreen(this.hideSidebar);
    }
}
